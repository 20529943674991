import { css } from "@emotion/react";
import { Input, InputProps } from "@mui/material";
import React from "react";
import LabelField from "../label-field";
import styled from "@emotion/styled";

function DefaultTextField(props: ITextFieldComponent) {
  const { label, value, errorsComponents, ...res } = props;

  const onChange = (e: any) => {
    if (props?.onChange) {
      props.onChange(e);
    }
  };

  const config: any = {
    ...res,
    ...{ id: props.name },
    onChange,
  };

  return (
    <div css={styles.contentInput} className="content-input-field">
      <LabelField label={label} positionLabel={props.positionLabel} />
      <Input
        label={undefined}
        css={styles.inputField}
        disableUnderline={true}
        {...config}
        value={value}
      />

      {errorsComponents && React.cloneElement(errorsComponents)}
    </div>
  );
}

export default DefaultTextField;

export interface ITextFieldComponent extends InputProps {
  name?: string;
  label?: string;
  positionLabel?: "center" | "right" | "left";
  onChange?: (st: any) => void;
  errorsComponents?: React.FunctionComponentElement<any>;
}

export const styles = {
  inputField: css({
    width: "100%",
    outline: "none",
    border: "1px solid var(--color-border)",
    borderRadius: "50px",
    padding: "8px 23px",
    background: "none",
    color: "var(--color-blue-main)",
    fontFamily: "var(--font-family)",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "var( --fs-body-small)",
    lineHeight: "21px",
    "& input::placeholder": {
      color: "var(--color-blue-main)",
      opacity: "1",
    },
    "&:hover": {
      border: "1px solid var(--color-border-hover)",
    },
  }),

  contentInput: css({
    width: "100%",
    // minHeight: "80px",
  }),

  inputErrors: css({
    width: "100%",
    color: "var(--color-red-error)",
    fontWeight: 500,
    paddingLeft: "10px",
    fontSize: "0.8125rem",
    display: "flex",
    alignItems: "center",
    "& .iconError": {
      fontSize: "0.8125rem",
      marginRight: 3,
    },
  }),
};

const Errors = styled.span``;
